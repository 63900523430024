@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --radius: 0.5rem;
  }

  * {
    @apply border-border dark:border-slate-800;
  }

  address {
    font-style: normal;
  }

  body {
    @apply bg-background text-muted-foreground;
  }

  blockquote p {
    @apply !m-0;
  }

  blockquote cite {
    @apply text-xs;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-6 font-bold leading-normal -tracking-[0.01em] text-foreground dark:text-white;
  }

  h1 {
    @apply text-3xl sm:text-4xl;
  }

  h2 {
    @apply text-2xl sm:text-3xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-md;
  }
}

@layer components {
  .material-input:focus {
    @apply ring-offset-transparent focus-visible:ring-0 focus-visible:ring-ring focus-visible:ring-offset-0;
  }

  .material-input:focus ~ .material-input__underline {
    width: 100%;
  }

  .material-input__underline {
    position: relative;
    display: block;
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    transition: 0.2s ease all;
    @apply bg-primary;
  }

  .hover-shadow {
    @apply relative top-0 shadow-sm transition-all duration-200 hover:-top-[.125rem] hover:shadow-lg hover:shadow-slate-500/20 dark:shadow-slate-850/20;
  }

  .testimonials-reversed > div:nth-child(even) {
    @apply flex-row-reverse;
  }

  .testimonials-reversed > div:nth-child(even) .testimonials__content {
    @apply ml-0 text-right md:mr-16;
  }

  .testimonials-slider .swiper-autoheight .swiper-slide {
    height: 100%;
  }

  .swiper .swiper-pagination {
    position: relative;
  }

  .swiper .swiper-pagination-bullets {
    @apply mt-10;
    --swiper-pagination-bottom: 0;
  }

  .swiper .swiper-pagination-bullet {
    @apply hover:bg-primary;
    --swiper-theme-color: theme("colors.primary.DEFAULT");
    --swiper-pagination-bullet-inactive-color: theme("colors.border");
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-size: 14px;
    --swiper-pagination-bullet-horizontal-gap: 6px;
  }

  .dark .swiper .swiper-pagination-bullet {
    --swiper-pagination-bullet-inactive-color: theme("colors.slate.700");
  }

  .color-scheme-initial {
    color-scheme: initial;
  }
}

html {
  scroll-behavior: smooth;
}

.btn-drop-toc {
  text-decoration: none !important;
}

/* SECTION BLOG SLIDER */
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "" !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  display: none;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-next svg,
.swiper-button-prev svg {
  width: 24px !important;
  height: 24px !important;
}

.swiper-button-next,
.swiper-button-prev {
  position: relative !important;
}

.swiper-slide.swiper-slide-active {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.swiper-slide.swiper-slide-active > .swiper-slide-active\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.swiper-slide.swiper-slide-active
  > .flex
  .grid
  .swiper-slide-active\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}



.rtl {
  direction: rtl;
  text-align: right;
}

.rtl-layout {
  /* Misalnya, membalik margin atau padding */
  margin-left: auto;
  margin-right: 0;
}

/* KODE CSS CUSTOM*/
.iframe-lightbox .btn-close {
  background-color: rgb(255, 6, 27) !important; /* Background putih */
  color: #ffffff !important; /* Warna "X" hitam */
  border-radius: 50%; /* Membuat tombol bulat */
  padding: 0.7rem; /* Tambahkan padding lebih besar */
  display: flex; /* Flexbox untuk centering */
  align-items: center; /* Centering vertikal */
  justify-content: center; /* Centering horizontal */
  width: 2.5rem; /* Atur lebar */
  height: 2.5rem; /* Atur tinggi */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Tambahkan bayangan */
}

/* Efek hover untuk tombol */
.iframe-lightbox .btn-close:hover {
  background-color: rgb(
    43,
    26,
    153
  ) !important; /* Ubah warna latar belakang saat hover */
  color: white !important; /* Ubah warna "X" saat hover */
}

.popover-content {
  max-width: 200px; /* Atur lebar maksimum sesuai kebutuhan */
  white-space: normal; /* Membiarkan teks membungkus ke baris berikutnya */
}

/* Tambahkan CSS ini ke file globals.css atau style module Anda */

/* Style dasar untuk container tombol */
.wp-block-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 1.5rem 0;
  margin-top: 5rem;
}

/* AREA UNTUK STYLE TOMBOL WORDPRESS */
/* Tambahkan CSS ini ke file globals.css atau style module Anda */

/* Style dasar untuk container tombol */
.wp-block-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 1.5rem 0;
  margin-top: 7rem;
}

/* Style untuk tombol default */
.wp-block-button__link {
  display: inline-block;
  transition: all 0.2s ease-in;
  position: relative;
  min-width: 13rem;
  overflow: hidden;
  z-index: 1;
  color: #090909;
  padding: 0.7em 1.7em;
  cursor: pointer;
  font-size: 18px;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  box-shadow:
    6px 6px 12px #c5c5c5,
    -6px -6px 12px #ffffff;
  text-align: center;
  text-decoration: none;
}

.wp-block-button__link:active {
  color: #666;
  box-shadow:
    inset 4px 4px 12px #c5c5c5,
    inset -4px -4px 12px #ffffff;
}

.wp-block-button__link:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scaleY(1) scaleX(1.25);
  top: 100%;
  width: 140%;
  height: 180%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.wp-block-button__link:after {
  content: "";
  position: absolute;
  left: 55%;
  transform: translateX(-50%) scaleY(1) scaleX(1.45);
  top: 180%;
  width: 160%;
  height: 190%;
  background-color: #0c0f3c;
  border-radius: 50%;
  display: block;
  transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
  z-index: -1;
}

.wp-block-button__link:hover {
  color: #ffffff;
  border: 1px solid #0c0f3c;
}

.wp-block-button__link:hover:before {
  top: -35%;
  background-color: #0c0f3c;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.wp-block-button__link:hover:after {
  top: -45%;
  background-color: #0c0f3c;
  transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

/* Responsif untuk mobile */
@media (max-width: 640px) {
  .wp-block-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .wp-block-button {
    width: 100%;
  }

  .wp-block-button__link {
    width: 100%;
  }
}

/* NOMOR HP DI FORM QUOTATION */
/* Sesuaikan dengan variabel Tailwind CSS jika diperlukan */
.PhoneInputInput {
  @apply w-full bg-transparent text-sm text-slate-800 focus:outline-none dark:text-white;
}

.PhoneInput {
  @apply flex items-center;
}

.PhoneInputCountry {
  @apply mr-2;
}

.PhoneInputCountrySelectArrow {
  @apply text-muted-foreground;
}

/* WA LINK GENERATOR */
.hasil-link-wa {
  word-break: break-word;
}

/* BUSINESS NAME GENERATOR */
.kostum-bng {
  background-image: linear-gradient(#ffffff, #f7f9fa);
  width: 100%;
}

.text-green-600 {
  color: #16a34a;
}

.text-red {
  color: red;
}

.wisbn {
  color: #8f31f7;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .mobileBackground {
    position: relative;
    background-image: url("/hero/bg-walink-mobile.webp");
    background-size: cover;
    background-position: center;
  }

  .mobileBackground .next-image {
    display: none !important;
  }
}

@media (max-width: 820) {
  .mobileBackground {
    position: relative;
    background-image: url("/hero/bg-walink-mobile.webp");
    background-size: cover;
    background-position: center;
  }

  .mobileBackground .next-image {
    display: none !important;
  }
}


.bpComposerPoweredBy {
  display: none;
}